export default {
  addSupplier: 'Добавить поставщика',
  updateCurrentSupplier: 'Обновить текущего поставщика',
  supplier: 'Поставщик',
  saler: 'Продавец',
  boughtAdmin: 'Куплено администратором',
  confirmedWarehouser: 'Подтверждено кладовщиком',
  //suppliers fields
  fullName: 'Полное имя',
  phone: 'Телефон',
  phone1: 'Телефон 1',
  phone2: 'Телефон 2',
  email: 'Электронная почта',
  location: 'Местоположение',
  landmark: 'Ориентир',
  responsiblePerson: 'Ответственное лицо',
  telegram: 'Телеграм',
  company: 'Компания',
  productTopics: 'Темы продуктов',
  probability: 'Вероятность',
  conclusion: 'Заключение',
  advertisements: 'Реклама',
  supplierType: 'Тип поставщика',
  companyPosition: 'Должность в компании',
  organization: 'Организация',

  //supply report
  totalPrice: 'Общая цена',
  totalCount: 'Общее количество',
  ourDebtMoney: 'Наш долг',
  ourPaidMoney: 'Наши оплаченные деньги',
  productCount: 'Количество продуктов',

  supplyBy: 'Поставка от',

  boughtDate: 'Дата покупки',
  goToDoc: 'Перейти к документу',

  //supply history
  supplyHistoryTitle: 'История поставок',
  adminConfirmation: 'Подтверждение администратора',
  warehouserConfirmation: 'Подтверждение кладовщика',
  document: 'Документ',
  currency: 'Валюта',
  originalPrice: 'Оригинальная цена',
  discount: 'Скидка',
  paidMoney: 'Оплаченные деньги',
  debtMoney: 'Долговые деньги',
  documentSaved: 'Документ сохранен',

  warehouseConfirmed: 'Склад подтвержден',
  adminConfirmed: 'Администратор подтвержден',
  notConfirmed: 'Не подтверждено',

  // buy product
  buyProductTitle: 'Купить продукт у поставщика',
  dateToPay: 'Дата оплаты',
  products: 'Продукты',
  addProducts: 'Добавить продукты',
  paid: 'Оплачено',
  debt: 'Долг',

  productNotAdded: 'Продукт не добавлен',
}