export default {
  order: 'Заказ',
  orders: 'Заказы',
  ordersForManufacturers: 'Заказы для производителей',
  createOrder: 'Создать новый заказ',
  createOrderForManufacturer: 'Создать новый заказ для производителя',
  dashboard: 'Панель управления заказами и расчетами',

  myOrders: 'Мои заказы',
  noSellerYet: 'Пока нет продавца',
  inProcess: 'В процессе',
  notStarted: 'Не начато',

  urgently: 'Срочно',
  bron: 'Брон',
  bron2: 'Изначально он был забронирован временно ?',

  youHaveNoOrders: 'У вас нет заказов!',
  myOrder: 'Это мой заказ',
  warehouser: 'Кладовщик',
  recipient: 'Получатель',
  client: 'Клиент',
  start: 'Начать процесс заказа',

  // Statuses
  progress1: 'Заказ еще не принят продавцом!',
  progress2: 'Заказ принят продавцом, но кладовщик еще не выбран!',
  progress3: 'Для заказа выбран кладовщик, но кладовщик еще не принял заказ!',
  progress4: 'Кладовщик проверяет количество товаров в заказе!',
  progress5: 'Кладовщик закончил проверку товаров в заказе, и клиент забрал заказ сам!',
  progress6: 'Заказ находится в процессе доставки клиенту!',
  progress7: 'Заказ доставлен клиенту, но статус доставки еще не подтвержден клиентом!',
  progress8: 'Заказ был доставлен клиенту, и статус доставки был подтвержден клиентом, но количество товаров еще не подтверждено правильным.',
  progress9: 'Заказ доставлен клиенту, и клиент подтвердил правильность доставки и количества товаров!',
  progress10: 'Еще не началось!',
}