export default {
  addSupplier: "Yetkazib beruvchini qo'shish",
  updateCurrentSupplier: 'Joriy yetkazib beruvchini yangilash',
  supplier: 'Yetkazib beruvchi',
  saler: 'Sotuvchi',
  boughtAdmin: 'Sotib olgan admin',
  confirmedWarehouser: 'Tasdiqlangan omborchi',
  //suppliers fields
  fullName: "To'liq ism",
  phone: 'Telefon',
  phone1: 'Telefon 1',
  phone2: 'Telefon 2',
  email: 'Elektron pochta',
  location: 'Manzil',
  landmark: "Mo'ljal",
  responsiblePerson: "Mas'ul shaxs",
  telegram: 'Telegram',
  company: 'Kompaniya',
  paymentMethod: "To'lov usuli",
  productTopics: 'Mahsulot mavzulari',
  probability: 'Ehtimollik',
  conclusion: 'Xulosa',
  advertisements: 'Reklamalar',
  supplierType: 'Yetkazib beruvchi turi',
  companyPosition: 'Kompaniya pozitsiyasi',
  organization: 'Tashkilot',

  //supply report
  totalPrice: 'Umumiy narx',
  totalCount: 'Umumiy son',
  ourDebtMoney: 'Bizning qarz pulimiz',
  ourPaidMoney: "Bizning to'langan pulimiz",
  productCount: 'Mahsulot soni',

  supplyBy: 'Yetkazib beruvchi',

  boughtDate: 'Sotib olingan sana',
  goToDoc: "Hujjatga o'tish",

  //supply history
  supplyHistoryTitle: 'Yetkazib berish tarixi',
  adminConfirmation: 'Admin tasdiqlashi',
  warehouserConfirmation: 'Omborchi tasdiqlashi',
  document: 'Hujjat',
  currency: 'Valyuta',
  originalPrice: 'Asl narx',
  discount: 'Chegirma',
  paidMoney: "To'langan pul",
  debtMoney: 'Qarz pul',
  documentSaved: 'Hujjat saqlandi',

  warehouseConfirmed: 'Ombor tasdiqladi',
  adminConfirmed: 'Admin tasdiqladi',
  notConfirmed: 'Tasdiqlanmagan',

  // buy product
  buyProductTitle: 'Yetkazib beruvchidan mahsulot sotib olish',
  dateToPay: "To'lov sanasi",
  products: 'Mahsulotlar',
  addProducts: "Mahsulotlarni qo'shish",
  paid: "To'langan",
  debt: 'Qarz',

  productNotAdded: "Mahsulot qo'shilmadi",
}