export default {
  organizations: 'Организации',
  dashboard: 'Панель управления',
  warehouse: 'Склад',
  supply: 'Поставка',
  trade: 'Торговля',
  cash: 'Касса',
  hr: 'Человеческие ресурсы',
  production: 'Производство',
  finance: 'Финансы',
  accounting: 'Бухгалтерский учет',
  logistics: 'Логистика',
  edu: 'Образование',
  crm: 'CRM',
  bi: 'Бизнес-аналитика',
  services: 'Услуги с разными личностями',
  additions: 'Дополнительно',
  company: 'Компания',
  task: 'Задача',
  tasks: 'Задачи',
  transactions: 'Транзакции',
  payroll: 'Зарплата',

  // Trade
  clients: 'Клиенты',
  saleProductToClient: 'Продажа продукта клиенту',
  saledProductsToClientHistory: 'История проданных клиенту продуктов',

  // HR
  employee: 'Сотрудник',
  employees: 'Сотрудники',
  departments: 'Отделы',
  positions: 'Позиции',
  adminRoles: 'Роли администратора',
  attendanceHistory: 'История посещений',
  employeesContracts: 'Контракты сотрудников',
  comeOrWentCreator: 'Создатель Come or Went для сотрудников',

  // Additions
  locations: 'Локации',
  address: 'Адрес',
  conclusions: 'Выводы',
  probabilities: 'Вероятности',
  supplier_types: 'Типы поставщиков',
  topics: 'Темы',
  advertisements: 'Реклама',
  companyPositions: 'Должности компании',
  manufacturer: 'Производитель',
  brand: 'Бренд',
  group: 'Группа',
  uom: 'Единица измерения',
  uom2: 'Единица измерения',
  type: 'Тип',
  serviceGroup: 'Группа услуг',
  service: 'Услуга',
  pay_office: 'Выплатной пункт',
  operational_point: 'Операционная точка',
  currencies: 'Валюты',
  healthStatus: 'Состояние здоровья',
  nationality: 'Национальность',
  language: 'Язык',
  languagePosition: 'Языковая позиция',
  livingStandards: 'Условия проживания',
  product_metadata: 'Метаданные продукта',
  category: 'Категория',

  // Warehouse
  warehousesList: 'Список складов',
  productDatas: 'Продукты',
  productsRemainders: 'Остатки продуктов',
  productsList: 'Список продуктов',
  productAdd: 'Добавить продукт',
  variantsList: 'Список вариантов',
  variantAdd: 'Добавить вариант',
  warehouseReport: 'Отчет о складе',
  warehouses: 'Склады',
  variants: 'Варианты',
  inventoryWarehouseBalance: 'Инвентаризация остатков на складе',
  changeCostProductsRemaining: 'Изменить стоимость оставшихся продуктов',
  settingSellingPriceProducts: 'Установление цены продажи продукции',

  // Services
  servicesHistory: 'История услуг',
  servicesPersons: 'Личности',
  servicesDifferentPersons: 'Разные личности',
  servicesPersonsList: 'Список личностей',
  servicesPersonAdd: 'Добавить новую личность',
  servicesHistoriesList: 'История купли-продажи услуг',
  servicesHistoryAdd: 'Регистрация услуг',

  // Payroll
  paymentSalaryToEmployees: 'Выплата заработной платы сотрудникам',
  payrollHistory: 'История операций по заработной плате сотрудников',
  recalculateSalaries: 'Пересчитать зарплаты',

  // Tasks
  myTasks: 'Мои задачи',
  tasksAssign: 'Назначить новую задачу',
  underControl: 'Задачи под контролем',
  allActiveTasks: 'Все активные задачи',
  recurringTasks: 'Повторяющиеся задачи',
  taskCreate: 'Создание задачи',
  taskEdit: 'Редактирование задачи',
  taskCancellation: 'Отмена задачи',
  taskDeleteFromHistory: 'Удалить задачу из истории',
  tasksHistory: 'История задач',

  // Recurring Tasks
  recurringTasksList: 'Список повторяющихся задач',
  createRecurringTask: 'Создать повторяющуюся задачу',

  // Cash
  typesOfExpenses: 'Виды расходов',
  changeMainCurrency: 'Изменить основную валюту',
  changeCurrenciesExchangeRates: 'Изменить курсы обмена валют',

  // Supply
  suppliers: 'Поставщики',
  supplyHistory: 'История поставок',
  buyProductFromSupplier: 'Купить продукт у поставщика',
  supplyReport: 'Отчет о поставках',


  products: 'Продукты',
  categories: 'Категории',
  materials: 'Материалы',
  colors: 'Цветы',
  color: 'Цвет',
  paymentMethods: 'Способы оплаты',
  paymentMethod: 'Способ оплаты',
  news: 'Новости',
  selfADS: 'Самореклама',
  users: 'Пользователи',
  subscribers: 'Подписчики',
  accountingBook: 'Бухгалтерская книга',
  manufacturersWarehouse: 'Склад производителя',
  telegramBotSubscribers: 'Подписчики бота Telegram',
  admins: 'Админы',
  roles: 'Роли',
  telegramSettings: 'Настройки Телеграм',
  territories: 'Территории',
  clientPositions: 'Позиции клиентов',
  warehouseLocations: 'Расположение складов',
  cars: 'Автомобили',
  season: 'Сезон',
  status: 'Статус',
  productsBarcodeCreator: 'Генерация штрих-кода для продуктов',
  productsMarkerCreator: 'Маркировка продукции',
  productsPDFCreator: 'Продукты PDF Creator',
  ordersAcceptance: 'Прием заказов',
  overOfStock: 'Перерасход запасов',
}