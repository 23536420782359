export default {
  order: 'Order',
  orders: 'Orders',
  ordersForManufacturers: 'Orders for Manufacturers',
  createOrder: 'Create new Order',
  createOrderForManufacturer: 'Create new Order for Manufacturer',
  dashboard: 'Orders and Accountings Dashboard',

  myOrders: 'My Orders',
  noSellerYet: 'No seller yet',
  inProcess: 'In Process',
  notStarted: 'Not Started',

  urgently: 'Urgently',
  bron: 'Bron',
  bron2: 'Was it initially booked temporarily ?',

  youHaveNoOrders: 'You have no orders!',
  myOrder: 'This is my order',
  warehouser: 'Warehouser',
  recipient: 'Recipient',
  client: 'Client',
  start: 'Start order process',

  // Statuses
  progress1: 'The order has not yet been accepted by the seller!',
  progress2: 'The order has been accepted by the seller and the Warehouser has not been selected yet!',
  progress3: 'A Warehouseman has been selected for the order, but the Warehouseman has not accepted the order yet!',
  progress4: 'The warehouseman is checking the number of products on the order!',
  progress5: 'The Warehouseman finished checking the products in the order and the customer picked up the order himself!',
  progress6: 'The order is in the process of delivery to the customer!',
  progress7: 'The order has been delivered to the customer, but the delivery status has not yet been confirmed by the customer!',
  progress8: 'The order has been delivered to the customer and the delivery status has been confirmed by the customer, but the number of products has not yet been confirmed to be correct.',
  progress9: 'The order has been delivered to the customer and the customer has confirmed that the delivery and number of products are correct!',
  progress10: 'Not started yet!',
}