import { ReactNode, createContext, useCallback, useContext } from 'react'

// Redux store
import { expireSession, reset } from 'store/store'
import { useDispatch } from 'react-redux'

// Hooks
import { useTypedSelector } from 'hooks'
import { useTranslation } from 'react-i18next'

// Components
import { SessionModal } from 'components'

// Creating Context
const SessionContext = createContext<{ showSessionEnding: () => void } | null>(null)

export const useSession = () => {
  const context = useContext(SessionContext)
  if (!context) {
    console.warn('useSession must be used within a SessionProvider')
    return { showSessionEnding: () => null }
  }
  return context
}

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sessionIsExpired } = useTypedSelector((s) => s.store)

  const showSessionEnding = useCallback(() => dispatch(expireSession()), [dispatch])
  const logOut = useCallback(() => dispatch(reset()), [dispatch])

  return (
    <SessionContext.Provider value={{ showSessionEnding }}>
      {children}
      {sessionIsExpired && <SessionModal logOut={logOut} />}
    </SessionContext.Provider>
  )
}