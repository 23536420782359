export default {
  addWarehouse: "Ombor qo'shish",

  //product table
  productTitle: 'Mahsulot nomi',
  price: 'Narx',
  category: 'Kategoriya',
  group: 'Guruh',
  image: 'Rasm',
  product: 'Mahsulot',
  warehouse: 'Ombor',
  count: 'Soni',
  barcode: 'Shtrix kod',
  uom: "O'lchov birligi",
  brand: 'Brend',
  type: 'Turi',
  additionFields: "Qo'shimcha maydonlar",

  selectProduct: 'Mahsulotni tanlang',
  minSaleCount: 'Minimal sotish soni',
  maxSaleCount: 'Maksimal sotish soni',
  salePrice: 'Sotish narxi',
  purchasePrice: 'Xarid narxi',
  quantityInPackage: 'Paketdagi miqdor',
  minPackageCountBeforeUnitSale: 'Birlik sotishdan oldin minimal paket soni',

  //warehouse report
  reload: 'Qayta yuklash',
  goToSupplyHistory: "Ta'minot tarixiga o'tish",
  goToSaleHistory: "Sotish tarixiga o'tish",
  incomeCount: 'Kirim soni',
  outlayCount: 'Chiqim soni',
  incomeMoney: 'Kirim puli',
  outlayMoney: 'Chiqim puli',
  activeWarehouses: 'Faol omborlar',
  detailedInfoToSelectedWarehouse: "Tanlangan omborga batafsil ma'lumot",
  detailedInfoToSelectedProduct: "Tanlangan mahsulotga batafsil ma'lumot",

  actionHappenDate: "Harakat sodir bo'lgan sana",
  indicator: "Ko'rsatkich",
  document: 'Hujjat',

  characteristics: 'Xarakteristika',
}